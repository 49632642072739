import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  Divider,
  TextField,
} from "@mui/material";
import DownloadImage from "../assets/images/download.png";
import axios from "axios";
import { SubmagicResponse } from "../extras/types";
import { ColorContext } from "../extras/ColorContext";
import { useTranslation } from 'react-i18next';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://youtubeshortsdownload.com/api';
var static_video_url = "";

const sampleSubmagicResponse: SubmagicResponse = {
    url: "https://www.youtube.com/watch?v=dQw4w9WgXcQ", // 视频的 URL
    formats: [
        {
            formatId: 18,
            label: "mp4 (360p)",
            type: "video_with_audio",
            ext: "mp4",
            width: 360, // 视频宽度
            height: 640, // 视频高度
            url: "https://example.com/video.mp4", // 视频下载链接
            bitrate: 702098, // 比特率
            fps: 30, // 帧率
            audioQuality: "AUDIO_QUALITY_LOW", // 音频质量
            audioSampleRate: "44100", // 音频采样率
            mimeType: "video/mp4; codecs=\"avc1.42001E, mp4a.40.2\"", // MIME 类型
            duration: 3, // 持续时间
        },
        {
            formatId: 140,
            label: "mp3",
            type: "audio_only",
            ext: "mp3",
            url: "https://example.com/audio.mp3", // MP3 下载链接
            bitrate: 128000, // 比特率
            duration: 3, // 持续时间
            width: 0, // 音频格式宽度（通常为 0）
            height: 0, // 音频格式高度（通常为 0）
            fps: 0, // 音频格式帧率（通常为 0）
            audioQuality: "AUDIO_QUALITY_HIGH", // 音频质量
            audioSampleRate: "44100", // 音频采样率
            mimeType: "audio/mpeg", // MIME 类型
        }
    ],
    thumbnailUrl: "https://i.ytimg.com/vi/dQw4w9WgXcQ/hqdefault.jpg", // 视频缩略图 URL
    defaultFormatId: 18, // 默认格式 ID
    duration: "216", // 视频时长（秒）
    title: "Rick Astley - Never Gonna Give You Up (Official Music Video)", // 视频标题
};

function YoutubeConverterMp3Page() {
  const colorContex = useContext(ColorContext);
  const scrollRef = useRef<any>(null);
  const [videoUrl, setVideoUrl] = useState("");
  const [audioResponse, setAudioResponse] = useState<SubmagicResponse>(sampleSubmagicResponse);
  const [playVideo, setPlayVideo] = useState(false);
  const [isTermsAggred, setIsTermsAggred] = useState(false);
  const [isDownloadSuccess, setIsDownloadSuccess] = useState(false);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const scrollToDiv = () => {
    if (colorContex.point !== 0) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
      colorContex.setPoint(0);
    }
  };

  useEffect(() => {
    scrollToDiv();
    return () => {};
  }, [colorContex.point]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setVideoUrl(event.target.value);
    if (videoUrl !== "" || videoUrl.includes("youtu")) {
      setPlayVideo(true);
    } else {
      setPlayVideo(false);
    }
  };

  const isValidYoutubeUrl = (url: string): boolean => {
    const pattern = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
    return pattern.test(url);
  };

  const fetchDownloadableLink = (): void => {
    if (!isValidYoutubeUrl(videoUrl)) {
      alert("A Valid Youtube Video URL is Required!!");
      return;
    }
    handleOpen();
    axios.get(API_BASE_URL + videoUrl).then(
      (result) => {
        setAudioResponse(result.data);
        setIsDownloadSuccess(true);
        setPlayVideo(true);
        static_video_url = result.data.url;
        setTimeout(() => {
          handleClose();
          setVideoUrl(static_video_url);
        }, 2000);
      },
      (error) => {
        handleClose();
        alert("Something went wrong while hitting data.." + error);
      }
    );
  };

  const openLink = (audioUrl: string): any => {
    if (audioUrl === "" || audioUrl.length < 20) {
      alert("Something went wrong while generating download link, try again..");
      return;
    }
    window.open(audioUrl, "_blank");
  };

  const backdrop = (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      onClick={handleClose}
    >
      <div className="flex flex-col items-center">
        <CircularProgress color="inherit" />
        <h1 className="font-extrabold m-2 text-white text-xl">
          Communicating with server...
        </h1>
      </div>
    </Backdrop>
  );

  return (
    <div ref={scrollRef} className="md:m-10 sm:m-5 flex flex-col items-center justify-center">
      {backdrop}
      <div className="flex flex-col items-center border border-gray-500 shadow-lg p-4">
        <TextField
          fullWidth
          value={videoUrl}
          onChange={handleChange}
          id="url-input"
          label={t("HomePage.heading")}
          variant="outlined"
        />
        <Button
          onClick={fetchDownloadableLink}
          sx={{ marginTop: "20px", marginBottom: "10px", width: "200px" }}
          variant="contained"
        >
          {t("HomePage.btntext")}
        </Button>
        <h3 className="text-xs text-center w-80 m-2 p-2">
          A direct prompt to download MP3 will get triggered if video has only one format else a list of downloadable audio will get presented.
        </h3>
        <Divider color="black" />
      </div>

      {isDownloadSuccess && (
        <div className="border-2 text-center border-blue-500 shadow-sm p-4">
          <div className="flex flex-col items-center md:flex-row font-mono mb-5 justify-center">
            <h3 className="font-bold text-xl">{t("HomePage.successheading")}</h3>
            <img className="m-2" width="30px" height="30px" alt="download" src={DownloadImage} />
          </div>

          {audioResponse.formats.map((format, index) => {
            if (format.ext !== 'mp3') return null; // 只显示 MP3 格式
            return (
              <Button
                sx={{ margin: "10px", color: "blue", fontWeight: "bold" }}
                key={index}
                variant="outlined"
                onClick={() => openLink(format.url)}
              >
                {format.label} Download 
              </Button>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default YoutubeConverterMp3Page; 