import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importing language files
import translationEN from './locales/en/common.json';
import translationDE from './locales/de/common.json';
import translationZH from './locales/zh/common.json';
import translationFR from './locales/fr/common.json';
import translationNL from './locales/nl/common.json';
import translationTR from './locales/tr/common.json';
import translationFI from './locales/fi/common.json';

const getInitialLanguage = () => {
  return localStorage.getItem('language') || JSON.stringify({ lang: 'en' });
};

i18next
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: translationEN },
      de: { translation: translationDE },
      zh: { translation: translationZH },
      fr: { translation: translationFR },
      nl: { translation: translationNL },
      tr: { translation: translationTR },
      fi: { translation: translationFI },
    },
    lng: getInitialLanguage(),
    fallbackLng: 'en', // fallback language
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

export default i18next;