import { useState, useEffect } from "react";
import "./App.css";
import Footer from "./components/Footer";
import { ColorContext } from "./extras/ColorContext";
import UpMenu from "./components/UpMenu";
import Home from "./pages/HomePage";
import YoutubeConverterMp3Page from './pages/YoutubeConverterMp3Page';
import SimpleIntro from "./components/SimpleIntro";
import FaqList from "./components/FaqList";
import { I18nextProvider } from 'react-i18next';
import i18next from './i18n';
import { useTranslation } from 'react-i18next';
import { Route, Routes, Navigate, useNavigate, useParams } from 'react-router-dom';

function App() {
  const [color, setColor] = useState<string>("");
  const [point, setPoint] = useState<number>(0);
  const handleColorChange = (color: string) => {
    setColor(color);
  };
  const handlePointChange = (p: number) => {
    setPoint(p);
  };
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { lang } = useParams<{ lang?: string }>();

  useEffect(() => {
    const currentLang = i18n.language;
    if (lang && lang !== currentLang) {
      i18n.changeLanguage(lang);
    } else if (!lang) {
      navigate(`/${currentLang}`, { replace: true });
    }
  }, [lang, i18n, navigate]);

  return (
    <I18nextProvider i18n={i18next}>
      <div className="overflow-hidden">
        <UpMenu />
        <ColorContext.Provider
          value={{
            color: color,
            point: point,
            setPoint: handlePointChange,
            setColor: handleColorChange,
          }}
        >
          <Routes>
            <Route path="/:lang?" element={
              <>
                <SimpleIntro
                  tag={t("SimpleIntro.tag")}
                  heading={t("SimpleIntro.heading")}
                  subtitle={t("SimpleIntro.subtitle")}
                  btntext={t("SimpleIntro.btntext")}
                />
                <Home />
              </>
            } />
            <Route path="/youtube-converter-mp3-online" element={<YoutubeConverterMp3Page />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </ColorContext.Provider>
        <div id="container-186557b5c838d84d77a57ca738f0c069"></div>
        <FaqList />
        <Footer />
      </div>
    </I18nextProvider>
  );
}

export default App;